import React from 'react';
import { Twitter, Linkedin, Instagram, FileText } from 'lucide-react';
import GlowingText from './GlowingText';

const Header = () => {
  return (
    <div className="fixed top-0 left-0 z-20 p-8">
      <div className="animate-header-fade-in">
        <GlowingText
          text="IAN CURTIS"
          className="text-3xl md:text-4xl text-white/90 font-['Space_Grotesk'] tracking-wider mb-1"
        />
        <GlowingText
          text="AI + XR TECHNOLOGIST"
          className="text-base md:text-lg font-['Space_Grotesk'] tracking-wider text-gray-300/90"
        />
        <GlowingText
          text="Hey! I'm Ian, and I love creating experiences where AI meets XR. Over the past 7 years, I've been exploring new ways to make digital interactions more natural and engaging. Whether it's AR, VR, or web-based experiences, I'm always excited to push creative and technical boundaries — xrarchitect"
          className="max-w-md text-sm font-['Space_Grotesk'] tracking-wide text-gray-300/90 mt-4 leading-relaxed hidden md:block"
        />
        <div className="text-white/90 text-sm mt-4 mb-4">
          Check out my recent Web 3D Editor project{' '}
          <a
            href="https://thebrowserlab.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-blue-400 transition-colors"
          >
            here
          </a>
        </div>
        <div className="flex gap-4 mt-4">
          <a
            href="https://www.linkedin.com/in/ian-curtis-138492102/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:scale-110 hover:text-blue-400 transition-all duration-300"
            aria-label="LinkedIn"
          >
            <Linkedin className="w-5 h-5" />
          </a>
          <a
            href="https://x.com/XRarchitect"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:scale-110 hover:text-blue-400 transition-all duration-300"
            aria-label="Twitter"
          >
            <Twitter className="w-5 h-5" />
          </a>
          <a
            href="https://www.instagram.com/xr.architect/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:scale-110 hover:text-blue-400 transition-all duration-300"
            aria-label="Instagram"
          >
            <Instagram className="w-5 h-5" />
          </a>
          <a
            href="https://indd.adobe.com/view/521e39c0-f627-486c-b487-9a7d9582ebcf"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:scale-110 hover:text-blue-400 transition-all duration-300"
            aria-label="CV/Resume"
          >
            <FileText className="w-5 h-5" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;